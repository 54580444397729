import "./assets/vendor/bootstrap/js/bootstrap.bundle.js";
import AOS from  "aos";
import GLightbox from  "./assets/vendor/glightbox/js/glightbox.min.js";
import Swiper from "./assets/vendor/swiper/swiper-bundle.min.js";


(function() {
    AOS.init();
    const glightbox = GLightbox({
        selector: '.glightbox'
    });

})();

// <script type="module" src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
// <script src="assets/vendor/aos/aos.js"></script>
// <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
// <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
// <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
// <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
// <script src="assets/vendor/php-email-form/validate.js"></script>